import "./chat.css"

const Chat = () => {
    return (
        <div className="chat-container">
            <h1 className="chat-header">Chat - In the works!</h1>
        </div>
    )
}

export default Chat